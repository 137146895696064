<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 基本信息</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="160px">
        <div class="flex flex_wrap">
        <el-form-item :label="!form.id?'产品编号前缀：':'产品编号：'" prop="product_no">
            <el-select v-model="form.product_no" v-if="!form.id" placeholder="请选择"
                style="width: 300px;" filterable>
                <el-option :label="item.value+'__'+item.title" v-for="(item,index) in zjlist"
                    :key="index" :value="item.value"></el-option>
            </el-select>
            <el-input v-if="form.id" disabled style="width: 300px" v-model="form.product_no"
                placeholder="请输入产品编号" />
        </el-form-item>
        <el-form-item label="产品编号：" prop="no">
            <el-input v-model="form.no" placeholder="请输入产品编号" style="width: 300px" />
        </el-form-item>
        <el-form-item label="品名：" prop="product_name">
            <el-input v-model="form.product_name" @blur="get_zjm" placeholder="请输入品名" style="width: 300px" />
        </el-form-item>
        <el-form-item label="助记码：" prop="mnemonic_code">
            <el-input v-model="form.mnemonic_code" placeholder="请输入助记码" style="width: 300px" />
        </el-form-item>
        <el-form-item label="规格：" prop="spec_value_id">
            <!-- <el-select v-model="form.spec_value_id" placeholder="请选择" style="width:300px" filterable>
                <el-option :label="item.title" v-for="(item,index) in speclist" :key="index"
                    :value="item.spec_value_id"></el-option>
            </el-select> -->
            <el-input style="width: 300px" v-model="form.spec_value_id" placeholder="请输入产品规格" />
        </el-form-item>
        <el-form-item label="型号：" prop="model">
            <el-input v-model="form.model" placeholder="请输入型号" style="width: 300px" />
        </el-form-item>
        <el-form-item label="产品注册证名称:" prop="register_cert_name">
            <el-input v-model="form.register_cert_name" placeholder="请输入产品注册证名称" style="width: 300px" />
        </el-form-item>
        <el-form-item label="产品注册证号:" prop="register_cert_no">
            <el-input v-model="form.register_cert_no" placeholder="请输入产品注册证号" style="width: 300px" />
        </el-form-item>
        <el-form-item label="注册证到期时间：" prop="register_cert_expire">
            <el-date-picker v-model="form.register_cert_expire" type="date" 
                value-format="YYYY-MM-DD" placeholder="选择到期日期" style="width:300px">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="包装单位" prop="pack_unit_id">
            <el-select v-model="form.pack_unit_id" placeholder="请选择" style="width:300px" filterable>
                <el-option :label="item.title" v-for="(item,index) in bzlist" :key="index"
                    :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        
        <el-form-item label="生产厂家:" prop='manufacturer'>
            <el-input v-model="form.manufacturer" placeholder="请输入生产厂家" style="width:300px" />
        </el-form-item>
        <!-- <el-form-item label="产地:" prop='origin'>
            <el-input v-model="form.origin" placeholder="请输入产地" style="width:300px" />
        </el-form-item> -->
        <el-form-item label="类别:" prop='type'>
            <el-select v-model="form.type" placeholder="请选择" style="width:300px" filterable>
                <el-option :label="item.title" v-for="(item,index) in lblist" :key="index"
                    :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <!-- <el-form-item label="产品分类：" prop="category_id">
            <el-cascader style="width:300px" v-model="form.category_id" :options="fllist"
                :props="{ expandTrigger: 'hover',checkStrictly: true,emitPath:false,value:'id',label:'title',children:'_child'}"
                clearable></el-cascader>
        </el-form-item> -->
        <el-form-item label="储存条件:" prop="storage_condition_id">
            <el-select v-model="form.storage_condition_id" placeholder="请选择" style="width:300px"
                filterable>
                <el-option :label="item.title" v-for="(item,index) in cclist" :key="index"
                    :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        </div>
        <el-form-item label="进价:" prop="purchase_price">
            <el-input v-model="form.purchase_price" placeholder="请输入进价" style="width:120px" />
            元
        </el-form-item>
        <el-form-item label="零售价:" prop="price">
            <el-input v-model="form.price" placeholder="请输入零售价" style="width:120px" />
            元
        </el-form-item>
        <el-form-item label="产品申请原因:" prop="apply_reason">
          <el-input type="textarea" v-model="form.apply_reason" placeholder="请输入产品申请原因" style="width:450px" />
        </el-form-item> 
        
        <el-form-item label="备注:" prop='remark'>
            <el-input type="textarea" v-model="form.remark" placeholder="请输入备注" style="width:450px" />
        </el-form-item>

        <div class="con_title"><span></span> 审批信息</div>
        <div class="flex_ac" v-for="(item2,index2) in sh_arr" :key="index2">
            <el-form-item :label="item2.title+'意见：'" style="width:400px">
              <el-select v-model="item2.opinion" placeholder="请选择意见" style="width:100%">
                <el-option label="同意" value="1"></el-option>
                <el-option label="不同意" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经办人:" style="width:400px">
              <el-select v-model="item2.admin_id" placeholder="请选择"
                  style="width: width:100% " filterable>
                  <el-option :label="item.full_name+'('+item.department_name+')'" v-for="(item,index) in rylist" v-show="item['is_'+item2.key]==1" 
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="审核日期：" style="width:400px">
              <el-date-picker
                v-model="item2.audit_date"
                type="date" value-format="YYYY-MM-DD"
                placeholder="选择日期"
                style="width:100%"
              >
              </el-date-picker>
            </el-form-item>
        </div>
    </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
</template>

<script>
export default {
  name: "addCommodity",
  data() {
    return {
      form: {
          product_no:'',
          product_name:'',
          mnemonic_code:'',
          register_cert_name:'',
          register_cert_no:'',
          register_cert_expire:'',
          pack_unit_id:'',
          model:'',
          spec_value_id:'',
          purchase_price:'',
          price:'',
          manufacturer:'',
          storage_condition_id:'',
          type:'',
          apply_reason:'',
          origin:'',
          stock_upper:'',
          stock_lower:'',
          coding:'',
          module_coding:'',
          medical_insurance_coding:'',
          imageIds:'',
          category_id:''
          
      },
      sh_arr:[
          {admin_id:'',opinion:'1',title:'业务部',key:'salesman'},
          {admin_id:'',opinion:'1',title:'质管部',key:'quality'},
          {admin_id:'',opinion:'1',title:'销售部',key:'sale'},
          {admin_id:'',opinion:'1',title:'总经理',key:'president'}
      ],
      rules: {
          product_no: [{
              required: true,
              message: "产品编号前缀不能为空",
              trigger: "blur"
          }],
          product_name: [{
              required: true,
              message: "品名不能为空",
              trigger: "blur"
          }],
          // mnemonic_code: [{
          //     required: true,
          //     message: "助记码不能为空",
          //     trigger: "blur"
          // }],
          
          // register_cert_name: [{
          //     required: true,
          //     message: "注册证名称不能为空",
          //     trigger: "blur"
          // }],
          // register_cert_no: [{
          //     required: true,
          //     message: "注册证号不能为空",
          //     trigger: "blur"
          // }],
          // register_cert_expire: [{
          //     required: true,
          //     message: "注册证到期时间不能为空",
          //     trigger: "blur"
          // }],
          
          pack_unit_id: [{
              required: true,
              message: "包装单位不能为空",
              trigger: "blur"
          }],
          // model: [{
          //     required: true,
          //     message: "型号不能为空",
          //     trigger: "blur"
          // }],
          // spec_value_id: [{
          //     required: true,
          //     message: "规格不能为空",
          //     trigger: "blur"
          // }],
          
          
          // purchase_price: [{
          //     required: true,
          //     message: "进价不能为空",
          //     trigger: "blur"
          // }],
          // price: [{
          //     required: true,
          //     message: "零售价不能为空",
          //     trigger: "blur"
          // }],
          // manufacturer: [{
          //     required: true,
          //     message: "生产厂家不能为空",
          //     trigger: "blur"
          // }],
          // storage_condition_id: [{
          //     required: true,
          //     message: "存储条件不能为空",
          //     trigger: "blur"
          // }],
          // type: [{
          //     required: true,
          //     message: "类别不能为空",
          //     trigger: "blur"
          // }],
          // apply_reason: [
          //   { required: true, message: "申请理由不能为空",trigger: "blur"}
          // ],
          // origin: [{
          //     required: true,
          //     message: "产地不能为空",
          //     trigger: "blur"
          // }],
          
          // stock_upper: [{
          //     required: true,
          //     message: "库存上限不能为空",
          //     trigger: "blur"
          // }],
          // stock_lower: [{
          //     required: true,
          //     message: "库存下限不能为空",
          //     trigger: "blur"
          // }],
          // coding: [{
          //     required: true,
          //     message: "Code编码不能为空",
          //     trigger: "blur"
          // }],
          // module_coding: [{
          //     required: true,
          //     message: "组件编码不能为空",
          //     trigger: "blur"
          // }],
          // medical_insurance_coding: [{
          //     required: true,
          //     message: "医保编码不能为空",
          //     trigger: "blur"
          // }],
          // imageIds: [{
          //     required: true,
          //     message: "产品证件不能为空",
          //     trigger: "blur"
          // }],
          // category_id: [{
          //     required: true,
          //     message: "产品分类不能为空",
          //     trigger: "blur"
          // }]
      },
      cclist: [],
      speclist: [],
      zjlist: [],
      bzlist: [],
      lblist: [],
      fllist:[],
      rylist:[]
    };
  },
  created() {
      this.id = this.$route.query.id || '';
      if (this.id) {
          this.rules.product_no[0].required = false;
          this.get_data()
      }else{
          for(let i=0;i<this.sh_arr.length;i++){
              this.sh_arr[i].audit_date=this.today_val()
          }
      }
      this.getOptions()
  },
  methods: {
    get_zjm(index) {
        let _val = this.form.product_name;
        if (_val) {
            this.$httpGet("/backend/product.Product/getMnemonicCode", {
                product_name: _val
            }).then((res) => {
                if (res.status == 200) {
                    this.form.mnemonic_code = res.data
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            this.form.mnemonic_code = ''
        }
    },
    
    // 表单重置
    reset(pid) {
        this.form = {
           product_no:this.zjlist.length>0?this.zjlist[0].value:'',
           product_name:'',
           mnemonic_code:'',
           register_cert_name:'',
           register_cert_no:'',
           register_cert_expire:'',
           pack_unit_id:'',
           model:'',
           spec_value_id:'',
           purchase_price:'',
           price:'',
           manufacturer:'',
           storage_condition_id:'',
           type:'',
           apply_reason:'',
           origin:'',
           stock_upper:'',
           stock_lower:'',
           coding:'',
           module_coding:'',
           medical_insurance_coding:'',
           imageIds:'',
           category_id:''
        };
        this.sh_arr=[
          {admin_id:'',opinion:'1',title:'业务部',key:'salesman'},
          {admin_id:'',opinion:'1',title:'质管部',key:'quality'},
          {admin_id:'',opinion:'1',title:'销售部',key:'sale'},
          {admin_id:'',opinion:'1',title:'总经理',key:'president'}
        ]
        this.imgs = [];
        if (this.$refs['form']) {
            this.$refs['form'].clearValidate();
        }
    },
    /** 提交按钮 */
    submitForm: function(form) {
        this.$refs[form].validate((valid) => {
            if (valid) {
                
                if (form == 'form') {
                    let _data = {
                        ...this.form
                    };
                    let arr=[];
                    for(let i=0;i<this.sh_arr.length;i++){
                        if(!this.sh_arr[i].admin_id){
                            this.$message.error('请选择'+this.sh_arr[i].title+'经办人');
                            return false;
                        }
                    }
                    this.is_ajax = true;
                    _data.audit_array=JSON.stringify(this.sh_arr);
                    this.$httpPost("/backend/product.ProductFirst/save", _data).then((res) => {
                        if (res.status == 200) {
                            this.$message.success(this.form.id ? '更新成功' : '新增成功');
                            // if(this.form.id){
                                this.$router.go(-1)
                            // }else{
                            //     this.reset();
                            // }
                            
                        } else {
                            this.$message.error(res.message);
                        }
                        this.is_ajax = false;
                    }).catch((err) => {
                        console.log(err);
                        this.is_ajax = false;
                    });
                }
            }
        });
    },
    get_data() {
        this.$httpGet("/backend/product.ProductFirst/read", {
            product_first_id: this.id
        }).then((res) => {
            if (res.status == 200) {
                this.form = res.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    getOptions() {
        this.$httpGet("/backend/product.StorageCondition/index", {
            size: 1000000
        }).then((res) => {
            if (res.status == 200) {
                this.cclist = res.data.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        this.$httpGet("/backend/product.SpecValue/index", {
            size: 1000000
        }).then((res) => {
            if (res.status == 200) {
                this.speclist = res.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    
    
        this.$httpGet("/backend/SysConfig/index", {
            size: 10000000,
            groups: '产品编号前缀'
        }).then((res) => {
            if (res.status == 200) {
                this.zjlist = res.data.data;
                if(!this.id && this.zjlist.length>0){
                    this.form.product_no=this.zjlist[0].value
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        this.$httpGet("/backend/product.PackUnit/index", {
            size: 10000000
        }).then((res) => {
            if (res.status == 200) {
                this.bzlist = res.data.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    
        this.$httpGet("/backend/product.ProductType/index", {
            size: 10000000
        }).then((res) => {
            if (res.status == 200) {
                this.lblist = res.data.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        // this.$httpGet("/backend/product.Category/index", {}).then((res) => {
        //     if (res.status == 200) {
        //         this.fllist = res.data.list;
        //     } else {
        //         this.$message.error(res.message);
        //     }
        // }).catch((err) => {
        //     console.log(err);
        // });
        
        // 获取经办人
        this.$httpGet("/backend/sys.Admin/handlerList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.rylist = res.data;
                
                // 默认选中第一个
                if(!this.id && this.rylist.length>0){
                    for(let i=0;i<this.sh_arr.length;i++){
                        if(this.sh_arr[i].admin_id==''){
                            for(let k=0;k<this.rylist.length;k++){
                                if(this.rylist[k]['is_'+this.sh_arr[i].key]=='1'){
                                    this.sh_arr[i].admin_id=this.rylist[k].id;
                                    break;
                                }
                            }
                        }
                    }
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.box_input {
  div {
    &:first-child {
      img {
        display: none;
      }
    }
  }
}
.examin {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
::v-deep .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>
